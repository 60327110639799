import React, { useCallback, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Currency, PaymentLocation } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import { UtmAnalysisEventEnum, useManageUtmData } from "@/hooks/useManageUtmData";
import { usePayWithSavedCard } from "@/hooks/usePayWithSavedCard";
import { cardTypeToString, currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";

import {
  getUserCardsFromSession,
  getUserCardsFromSession_getUserCards,
} from "../../chat/graphql/__generated__/getUserCardsFromSession";
import { GET_USER_CARDS_FROM_SESSION } from "../../chat/graphql/GET_USER_CARDS";

import { FAST_PAYMENT_OPIONS_MINUTES } from "./constants";
import { FastPaymentProps, FastPaymentStatusEnum } from "./props";
import { getPaymentStatusClass } from "./utils";
import "./styles.scss";

const FastPayment = ({
  isSessionEndsSoon,
  expertRate,
  sessionRefetch,
  setIsShowGiftsBlock,
  isShowGiftsBlock,
  sessionId,
}: FastPaymentProps) => {
  const [isPaymentShown, setIsPaymentShown] = useState(isSessionEndsSoon);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [card, setCard] = useState<getUserCardsFromSession_getUserCards>();
  const [amount, setAmount] = useState<number>();
  const [paymentStatus, setPaymentStatus] = useState<FastPaymentStatusEnum>(
    FastPaymentStatusEnum.DEFAULT,
  );
  const { saveUtmData } = useManageUtmData();

  const { data, error, loading } = useQuery<getUserCardsFromSession>(GET_USER_CARDS_FROM_SESSION, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getUserCards.length > 0) {
        setCard(data.getUserCards.find((cardItem) =>
          cardItem.isDefault) ?? data.getUserCards[0]);
      }
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (isShowGiftsBlock) {
      setIsPaymentOpen(false);
      setPaymentStatus(FastPaymentStatusEnum.DEFAULT);
    }
  }, [isShowGiftsBlock]);

  useEffect(() => {
    if (isSessionEndsSoon) {
      setIsPaymentShown(true);
      setIsPaymentOpen(false);
      setPaymentStatus(FastPaymentStatusEnum.DEFAULT);
    } else {
      setIsPaymentShown(false);
      setIsPaymentOpen(false);
      setPaymentStatus(FastPaymentStatusEnum.DEFAULT);
    }
  }, [isSessionEndsSoon]);

  const paymentFailHandler = useCallback(() => {
    setIsPaymentInProgress(false);
    setPaymentStatus(FastPaymentStatusEnum.FAILURE);
  }, []);

  const paymentSuccessHandler = () => {
    setIsPaymentInProgress(false);
    setPaymentStatus(FastPaymentStatusEnum.SUCCESS);
  };

  const paymentCompleteHandler = useCallback((paymentId: number) => {
    setIsPaymentInProgress(false);
    // todo: uncomment after refactoring of calls
    // if (!userHasPayments) {
    //   marketingFirstSuccessPaymentHandler();
    //   setUserHasPayments(true);
    // }
    saveUtmData({
      eventType: UtmAnalysisEventEnum.PaymentSucceded,
      paymentID: paymentId,
    });
    sessionRefetch();
  }, [saveUtmData, sessionRefetch]);

  const { payWithSavedCard } = usePayWithSavedCard({
    onFail: paymentFailHandler,
    onSuccess: paymentSuccessHandler,
    onComplete: paymentCompleteHandler,
  });

  const closeFormHandler = () => {
    setIsPaymentOpen(false);
    setPaymentStatus(FastPaymentStatusEnum.DEFAULT);
    setIsPaymentShown(isSessionEndsSoon);
  };

  function paymentHandler(selectedAmount: number) {
    if (!card) {
      return;
    }
    setAmount(selectedAmount);
    setIsPaymentInProgress(true);
    payWithSavedCard(selectedAmount, card.id, PaymentLocation.session, Number(sessionId));
  }

  return (
    <>
      {paymentStatus === FastPaymentStatusEnum.SUCCESS && <Confetti />}
      <div
        className={`fast-payment${isPaymentShown && card ? " is-shown" : ""}${isPaymentOpen ? " is-unwrapped" : ""
        } ${getPaymentStatusClass(isPaymentInProgress, paymentStatus)}`}
      >
        <div
          className="fast-payment__short"
          onClick={() => {
            if (setIsShowGiftsBlock) {
              setIsShowGiftsBlock(false);
            }
            setIsPaymentOpen(true);
          }}
        >
          <div className="fast-payment__header">Продлить консультацию</div>
          <Icon type={IconTypeEnum.Dropdown} size={IconSizeEnum.Size20} />
        </div>
        <div className="fast-payment__full">
          <div className="fast-payment__header">Продлить консультацию</div>
          <div className="fast-payment__card">
            <span>Способ пополнения:</span>
            <Icon type={IconTypeEnum.PayMastercard} size={IconSizeEnum.Size20} />
            <span>
              {cardTypeToString(card?.type ?? "")}
              {" "}
              •
              {card?.number}
            </span>
          </div>
          <div className="fast-payment__options">
            {FAST_PAYMENT_OPIONS_MINUTES.map((duration, index) => {
              const optionAmount = expertRate * duration;
              return (
                <div
                  className="fast-payment__option"
                  key={index}
                  onClick={() =>
                    paymentHandler(optionAmount)}
                >
                  <div className="fast-payment__duration">
                    {duration}
                    {" "}
                    мин
                  </div>
                  <div className="fast-payment__cost">
                    {optionAmount}
                    {" "}
                    ₽
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            text="Отмена"
            onClick={closeFormHandler}
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.White}
          />
        </div>
        <div className="fast-payment__in-progress">
          <Loader />
          <div className="fast-payment__in-progress-text">
            Зачисляем деньги
            <br />
            на ваш баланс...
          </div>
        </div>
        <div className="fast-payment__success">
          <div className="fast-payment__close" onClick={closeFormHandler}>
            <Icon type={IconTypeEnum.Close} size={IconSizeEnum.Size24} />
          </div>
          <div className="fast-payment__success-circle">
            <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
          </div>
          <div className="fast-payment__success-text">
            Ваш баланс пополнен
            <br />
            {amount ? `на ${priceToString(amount)} ${currencyToString(Currency.RUB)}` : ""}
          </div>
        </div>
        <div className="fast-payment__failure">
          <div className="fast-payment__close" onClick={closeFormHandler}>
            <Icon type={IconTypeEnum.Close} size={IconSizeEnum.Size24} />
          </div>
          <div className="fast-payment__failure-circle">
            <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size24} />
          </div>
          <div className="fast-payment__success-text">
            Платёж
            {" "}
            {amount ? `на ${priceToString(amount)} ${currencyToString(Currency.RUB)}` : ""}
            <br />
            отклонён
          </div>
        </div>
      </div>
    </>
  );
};

export default FastPayment;
