import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";

import VolumeLevel from "@/components/chat/mediaCheck/volumeLevel";
import Loader from "@/components/common/loader";
import { captureException, logToConsole } from "@/utils/commonUtils";
import { isBrowser } from "@/utils/env";
import MediaItem from "@components/chat/mediaCheck/Item";
import { MediaCheckProps, MediaDevicesType } from "@components/chat/mediaCheck/types";
// import UpdateNotification from "@components/chat/mediaCheck/UpdateNotification";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { DEVICES_RELOAD_DEBOUNSING_MS, MEDIA_CHECK_UI_DATA } from "./constants";
import {
  buildDevicesLists,
  checkSpeakers,
  getMicrophoneStatusText,
  getNewAutoSelectedDeviceId,
  getNewStream,
  getVideoStatusText,
  initMicAudioContext,
  stopStream,
} from "./utils";

import "./styles.scss";

const MediaCheck = ({ endCheckCallback }: MediaCheckProps) => {
  const [mediaDevices, setMediaDevices] = useState<MediaDevicesType>({
    cameraList: [],
    micList: [],
    speakersList: [],
  });
  const [stream, setStream] = useState<MediaStream | null>();
  const [micAnalyser, setMicAnalyser] = useState<AnalyserNode>();

  // User dropdown choices
  const [dropdownVideoId, setDropdownVideoId] = useState<string | null>();
  const [dropdownMicId, setDropdownMicId] = useState<string | null>();
  const [dropdownSpeakersId, setDropdownSpeakersId] = useState<string | null>();

  // The most probable device IDs that user would want to use without selecting anything himself
  const autoSelectedCameraIdRef = useRef<string>("");
  const autoSelectedMicIdRef = useRef<string>("");
  const autoSelectedSpeakersIdRef = useRef<string>("");

  // Device IDs to check and to pass to the session
  const videoId = dropdownVideoId ?? autoSelectedCameraIdRef.current;
  const micId = dropdownMicId ?? autoSelectedMicIdRef.current;
  const audioId = dropdownSpeakersId ?? autoSelectedSpeakersIdRef.current;

  // States of browser permissions
  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState<boolean>(true);
  const [isSpeakersEnabled, setIsSpeakersEnabled] = useState<boolean>(true);

  const [isDevicesReloading, setIsDevicesReloading] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  function isStartSessionButtonDisabled() {
    switch (true) {
      case !stream:
      case !isVideoEnabled && mediaDevices.cameraList.length > 1:
      case !isMicrophoneEnabled:
      case !micId:
      case !isSpeakersEnabled:
      case isDevicesReloading:
        return true;
      default:
        return false;
    }
  }

  const finishButtonClickHandler = () => {
    if (stream) {
      stopStream(stream);
    }
    endCheckCallback(
      {
        video: videoId,
        audio: audioId,
        microphone: micId,
      },
      isVideoEnabled && !!videoId,
    );
  };

  useEffect(() => {
    if (!isBrowser()) {
      return () => {};
    }
    // let hi = 0;
    // console.log("hi 00", hi);
    // hi += 1;

    let didCancel = false; // Flag to cancel heavy work on re-render
    let newStream: MediaStream | null;
    let enableVideo = true;
    let enableMic = true;

    async function initDevices() {
      // console.log("hi 06 013", hi);
      // hi += 1;
      await navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          // console.log("hi 07 014", hi);
          // hi += 1;
          if (didCancel) {
            return;
          }
          const devicesList = buildDevicesLists(devices);

          setMediaDevices((oldDevicesList) => {
            if (enableVideo) {
              const {
                newAutoSelectedDeviceId: newAutoSelectedCameraId,
                shouldResetDropdown: shouldResetCameraDropdown,
              } = getNewAutoSelectedDeviceId(devicesList.cameraList, oldDevicesList.cameraList);
              autoSelectedCameraIdRef.current = newAutoSelectedCameraId;

              setDropdownVideoId((oldDropdownVal) => {
                if (shouldResetCameraDropdown || !oldDevicesList.cameraList.find((device) =>
                  device.deviceId === oldDropdownVal)
                ) {
                  return null;
                }
                return oldDropdownVal;
              });
            } else {
              setDropdownVideoId(null);
            }

            if (enableMic) {
              const {
                newAutoSelectedDeviceId: newAutoSelectedMicId,
                shouldResetDropdown: shouldResetMicDropdown,
              } = getNewAutoSelectedDeviceId(devicesList.micList, oldDevicesList.micList);
              autoSelectedMicIdRef.current = newAutoSelectedMicId;

              setDropdownMicId((oldDropdownVal) => {
                if (shouldResetMicDropdown || !oldDevicesList.micList.find((device) =>
                  device.deviceId === oldDropdownVal)
                ) {
                  return null;
                }
                return oldDropdownVal;
              });
            } else {
              setDropdownMicId(null);
            }

            if (!devicesList.speakersList.length) {
              setIsSpeakersEnabled(false); // todo: check what shows on page
              setDropdownSpeakersId(null);
            } else {
              const {
                newAutoSelectedDeviceId: newAutoSelectedSpeakersId,
                shouldResetDropdown: shouldResetSpeakersDropdown,
              } = getNewAutoSelectedDeviceId(devicesList.speakersList, oldDevicesList.speakersList);
              autoSelectedSpeakersIdRef.current = newAutoSelectedSpeakersId;

              setDropdownSpeakersId((oldDropdownVal) => {
                if (shouldResetSpeakersDropdown || !devicesList.speakersList.find((device) =>
                  device.deviceId === oldDropdownVal)
                ) {
                  return null;
                }
                return oldDropdownVal;
              });
            }

            return devicesList;
          });

          setIsDevicesReloading(false);
        })
        .catch((exception: Error) => {
          captureException(exception, "Setting handlers for video session"); // todo: rename
          logToConsole("error", exception);
          return null;
        });
    }

    // Async function is not in utils because of https://github.com/facebook/react/issues/14326
    // Page init logic: requesting permissions, setting stream, initing dropdowns etc.
    async function init() {
      // console.log("hi 01", hi);
      // hi += 1;
      // Pops browser permissions dialog and gives access to device list if permissions are given
      newStream = await navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .catch((exception: Error) => {
          // console.log("hi 02", hi);
          // hi += 1;
          captureException(exception, "getUserMedia audio and video");
          return navigator.mediaDevices.getUserMedia({ audio: true });
        })
        .catch((exception: Error) => {
          // console.log("hi 03", hi);
          // hi += 1;
          captureException(exception, "getUserMedia audio");
          enableMic = false;
          setIsMicrophoneEnabled(false);
          return navigator.mediaDevices.getUserMedia({ video: true });
        })
        .catch((exception: Error) => {
          // console.log("hi 04", hi);
          // hi += 1;
          captureException(exception, "getUserMedia video");
          enableVideo = false;
          setIsVideoEnabled(false);
          return new MediaStream();
        })
        .catch((exception: Error) => {
          // console.log("hi 05", hi);
          // hi += 1;
          captureException(exception, "Creating empty stream for video session");
          return null;
        });

      if (didCancel) {
        return;
      }

      setStream(newStream);
    }

    // Logic to watch for divice changes
    let debounceTimer: NodeJS.Timeout | undefined;

    function debounce(func: () => void, timeout: number) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        debounceTimer = undefined;
        func();
      }, timeout);
    }

    async function reloadDevices() {
      // console.log("hi 010", hi); // 2 times
      // hi += 1;

      function deviceChangeHandler() {
        // console.log("hi 011", hi);
        // hi += 1;
        async function startNewStream() {
          // console.log("hi 012", hi);
          // hi += 1;
          // Re-initing devices first
          await initDevices();
          // Then creating stream with proper devices
          const streamAfterDeviceChange = await getNewStream(
            autoSelectedCameraIdRef.current,
            autoSelectedMicIdRef.current,
          );
          setStream(streamAfterDeviceChange);
        }

        startNewStream();
      }

      setIsDevicesReloading(true);
      debounce(deviceChangeHandler, DEVICES_RELOAD_DEBOUNSING_MS);
    }

    init().then(initDevices);

    navigator.mediaDevices.addEventListener("devicechange", reloadDevices);

    return () => {
      didCancel = true;
      if (newStream) {
        stopStream(newStream);
      }
      navigator.mediaDevices.removeEventListener("devicechange", reloadDevices);
    };
  }, []);

  useEffect(() => {
    if (!stream) {
      return () => {};
    }

    // Changing video preview on page
    if (videoRef.current && stream && stream.getVideoTracks().length) {
      videoRef.current.srcObject = stream;
      videoRef.current.volume = 0;
    }

    // Changing audio preview on page
    const micStreamData = initMicAudioContext(stream);
    setMicAnalyser(micStreamData?.analyser);

    async function destroyMicrophoneStream() {
      if (!micStreamData?.audioContext || micStreamData.audioContext.state === "closed") {
        return;
      }
      await micStreamData.audioContext.close();
    }

    return () => {
      if (stream) {
        stopStream(stream);
      }
      destroyMicrophoneStream();
    };
  }, [stream]);

  // Effect to run when video or mic dropdown changes
  useEffect(() => {
    // If no dropdown is changed - exit
    if (!dropdownVideoId && !dropdownMicId) {
      return;
    }

    async function startNewStream() {
      const newStream = await getNewStream(
        videoId,
        micId,
      );
      setStream(newStream);
    }

    startNewStream();
  }, [dropdownMicId, dropdownVideoId, micId, videoId]);

  const showVideoPreviewFlag = !!(stream && videoId && isVideoEnabled);

  return (
    <div className="page-width">
      <Helmet>
        <body
          className={`footer--hide header--hide body--full-height ${
            (!videoId || !isVideoEnabled) && "carrot-quest--chat-page"
          }`}
        />
      </Helmet>
      <div className={`media-check border ${stream ? "" : "media__subtext--skeleton"}`}>
        <div className="media-check__header h2">Проверка видеосвязи</div>
        <div className="media-check__sections">
          <p className="media-check__text-container">
            <span className="media-check__text">
              Разрешите доступ к камере и микрофону, и включите звук
            </span>
            <a className="media-check__hint-link" href="/chat/fix-media-access" target="_blank">
              Как&nbsp;это&nbsp;сделать
            </a>
          </p>

          {/* Old warning message */}
          {/* <UpdateNotification /> */}

          {/* Video */}
          <MediaItem
            name={MEDIA_CHECK_UI_DATA.video.title}
            icon={MEDIA_CHECK_UI_DATA.video.icon}
            mediaList={mediaDevices.cameraList}
            selectedMediaId={videoId}
            onChangeMedia={(id) =>
              setDropdownVideoId(id)}
            disabled={!isVideoEnabled || (!videoId && mediaDevices.cameraList.length < 2)}
            subtext={
              getVideoStatusText(
                mediaDevices.cameraList,
                isVideoEnabled,
              )
            }
            error={!isVideoEnabled}
            loading={isDevicesReloading}
          >
            <div className="video__test-container">
              {!showVideoPreviewFlag && !!stream && (
                <div className="video__test-container-icon">
                  <Icon type={IconTypeEnum.VideoOff} size={IconSizeEnum.Size32} />
                </div>
              )}
              {!showVideoPreviewFlag && !stream && <Loader />}
              <video
                className={`video__test ${showVideoPreviewFlag ? "" : "video__test--hidden"}`}
                autoPlay
                ref={videoRef}
                muted
                playsInline
              />
            </div>
          </MediaItem>
          <hr />

          {/* Microphone */}
          <MediaItem
            name={MEDIA_CHECK_UI_DATA.microphone.title}
            icon={MEDIA_CHECK_UI_DATA.microphone.icon}
            mediaList={mediaDevices.micList || []}
            selectedMediaId={micId}
            onChangeMedia={(id) =>
              setDropdownMicId(id)}
            disabled={!isMicrophoneEnabled || !micId}
            subtext={getMicrophoneStatusText(micId, isMicrophoneEnabled)}
            error={!isMicrophoneEnabled || !micId}
            loading={isDevicesReloading}
          >
            <>
              <VolumeLevel analyser={micAnalyser} />
              {isMicrophoneEnabled && micId && (
                <div className="media__subtext">{MEDIA_CHECK_UI_DATA.microphone.checkText}</div>
              )}
            </>
          </MediaItem>
          <hr />

          {/* Speakers */}
          <MediaItem
            name={MEDIA_CHECK_UI_DATA.audio.title}
            icon={MEDIA_CHECK_UI_DATA.audio.icon}
            mediaList={mediaDevices.speakersList || []}
            selectedMediaId={audioId}
            onChangeMedia={(id) =>
              setDropdownSpeakersId(id)}
            disabled={!isSpeakersEnabled || mediaDevices.speakersList.length < 2}
            subtext={!isSpeakersEnabled ? MEDIA_CHECK_UI_DATA.audio.subtext.noDevice : ""}
            error={!isSpeakersEnabled}
            loading={isDevicesReloading}
          >
            <>
              <audio src="/check_audio.wav" ref={audioRef} />
              <Button
                text={MEDIA_CHECK_UI_DATA.audio.button}
                size={ButtonSizeEnum.Small}
                color={ButtonColorEnum.Light}
                subtext=""
                disabled={!isSpeakersEnabled}
                onClick={() =>
                  checkSpeakers(audioId, audioRef.current)}
              />
              {isSpeakersEnabled && <div className="media__subtext">{MEDIA_CHECK_UI_DATA.audio.checkText}</div>}
            </>
          </MediaItem>
        </div>
        <div className="media__button-container">
          <Button
            className="media-check__finish"
            text={MEDIA_CHECK_UI_DATA.endCheckButton.text}
            onClick={finishButtonClickHandler}
            icon={stream && (!videoId || !isVideoEnabled)
              ? MEDIA_CHECK_UI_DATA.endCheckButton.icon : undefined}
            iconPosition={ButtonIconPositionEnum.Left}
            disabled={isStartSessionButtonDisabled()}
          />
          {/* Permission to camera should be given even if camera is turned off.
          If it's turned off - showing message */}
          {(isVideoEnabled && !videoId) && (
            <div
              className="media__subtext"
              dangerouslySetInnerHTML={{
                __html: MEDIA_CHECK_UI_DATA.endCheckButton.subtext.noCamera,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaCheck;
