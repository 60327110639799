import { gql } from "@apollo/client";

export const GET_GIFT_PAYMENT_STATE = gql`
  query getGiftPaymentState($paymentID: Int!) {
    getPayment(paymentID: $paymentID) {
      state
      errorValue
    }
  }
`;
