import React from "react";

import Lottie from "lottie-react";

import { GiftJsonAnimationProps } from "./types";

const GiftJsonAnimation = ({ animationPath, className }: GiftJsonAnimationProps) =>
  (
    <Lottie className={className} animationData={animationPath} />
  );

export default GiftJsonAnimation;
