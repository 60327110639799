import React, { useCallback, useEffect, useState } from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { PaymentError, PaymentState } from "@/autoGeneratedGlobalTypes";
import GiftAnimateComponent from "@/components/common/giftComponent";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { marketingGiftSentHandler } from "@/marketing/utils";
import { cardTypeToString } from "@/utils/globalTypesUtils";

import { getGiftPaymentState, getGiftPaymentStateVariables } from "../graphql/__generated__/getGiftPaymentState";
import {
  getUserCardsFromSession,
  getUserCardsFromSession_getUserCards,
} from "../graphql/__generated__/getUserCardsFromSession";
import { useProduct, useProductVariables } from "../graphql/__generated__/useProduct";
import { GET_GIFT_PAYMENT_STATE } from "../graphql/GET_GIFT_PAYMENT_STATE";
import { GET_USER_CARDS_FROM_SESSION } from "../graphql/GET_USER_CARDS";
import { USE_PRODUCT } from "../graphql/USE_PRODUCT";

import GiftPaymentFailure from "./components/giftPaymentFailure";
import GiftPaymentInProgress from "./components/giftPaymentInProgress";
import GiftPaymentSuccess from "./components/giftPaymentSuccess";
import { giftsData } from "./constants";
import { GiftContainerProps, GiftProcessStatusEnum } from "./types";

import "./styles.scss";

const GiftContainer = (props: GiftContainerProps) => {
  const {
    sessionId,
    isShow,
    setIsShowGiftsBlock,
    setClientGiftHistory,
    clientGiftHistory,
  } = props;
  const [choosedAnimationPath, setChoosedAnimationPath] = useState("");
  const [choosedSvgPath, setChoosedSvgPath] = useState<string>("");

  const [card, setCard] = useState<getUserCardsFromSession_getUserCards>();
  const [sendingGiftId, setSendingGiftId] = useState<number>();
  const [giftUnfinishedPaymentId, setGiftUnfinishedPaymentId] = useState<number>(0);

  const [paymentStatus, setPaymentStatus] = useState<GiftProcessStatusEnum>(
    GiftProcessStatusEnum.DEFAULT,
  );

  const { data, error, loading } = useQuery<getUserCardsFromSession>(GET_USER_CARDS_FROM_SESSION, {
    fetchPolicy: "network-only",
  });

  const [productPaymentMutation, { data: useProductData, loading: useProductLoading, error: useProductError },
  ] = useMutation<useProduct, useProductVariables>(USE_PRODUCT);

  const [getGiftPaymentStatus, {
    data: giftPaymentStatusData,
    loading: giftPaymentStatusLoading,
    error: giftPaymentStatusError,
  },
  ] = useLazyQuery<getGiftPaymentState, getGiftPaymentStateVariables>(GET_GIFT_PAYMENT_STATE, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!isShow) {
      setPaymentStatus(GiftProcessStatusEnum.DEFAULT);
    }
  }, [isShow]);

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getUserCards.length > 0) {
        setCard(data.getUserCards.find((cardItem) =>
          cardItem.isDefault) ?? data.getUserCards[0]);
      }
    }
  }, [data, error, loading]);

  const paymentHandler = (productID: number) => {
    if (!card || sessionId === null) {
      return;
    }
    setSendingGiftId(productID);
    productPaymentMutation({
      variables: {
        input: {
          cardID: card.id, productID, sessionID: Number(sessionId),
        },
      },
    });
  };

  const paymentCompleteHandler = useCallback(() => {
    setPaymentStatus(GiftProcessStatusEnum.SUCCESS);
    setIsShowGiftsBlock(false);
    setChoosedAnimationPath("");
    setClientGiftHistory([choosedSvgPath, ...clientGiftHistory]);

    const setDefaultTimeout = setTimeout(() => {
      setPaymentStatus(GiftProcessStatusEnum.DEFAULT);
    }, 3000);

    return () => {
      if (setDefaultTimeout) {
        clearTimeout(setDefaultTimeout);
      }
    };
  }, [choosedSvgPath, clientGiftHistory, setClientGiftHistory, setIsShowGiftsBlock]);

  const paymentFailHandler = useCallback((status : GiftProcessStatusEnum) => {
    setPaymentStatus(status);
    setChoosedAnimationPath("");
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let timeoutSuccess: NodeJS.Timeout;

    if (giftPaymentStatusData && !giftPaymentStatusLoading && !giftPaymentStatusError) {
      if (giftPaymentStatusData.getPayment.state === PaymentState.Success) {
        marketingGiftSentHandler(sendingGiftId);
        timeoutSuccess = setTimeout(() => {
          paymentCompleteHandler();
        }, 7000);
      }

      if (giftPaymentStatusData.getPayment.state === PaymentState.Failed) {
        setIsShowGiftsBlock(true);
        paymentFailHandler(GiftProcessStatusEnum.FAILURE);

        if (giftPaymentStatusData.getPayment.errorValue === PaymentError.InsufficientFundsOnBalance) {
          paymentFailHandler(GiftProcessStatusEnum.INSUFFICIENT_BALANCE);
        }
      }

      if (
        giftPaymentStatusData.getPayment.state === PaymentState.InProgress
      || giftPaymentStatusData.getPayment.state === PaymentState.Created
      ) {
        intervalId = setInterval(() => {
          getGiftPaymentStatus({ variables: { paymentID: giftUnfinishedPaymentId } });
        }, 3000);
      }
    }

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutSuccess);
    };
  }, [giftPaymentStatusData, giftPaymentStatusLoading, giftPaymentStatusError]);

  useEffect(() => {
    if (useProductData && !useProductLoading && !useProductError) {
      setPaymentStatus(GiftProcessStatusEnum.IN_PROGRESS);
      setGiftUnfinishedPaymentId(useProductData.useProduct.id);
      getGiftPaymentStatus({ variables: { paymentID: useProductData.useProduct.id } });
    }
  }, [getGiftPaymentStatus, useProductData, useProductError, useProductLoading]);

  return (
    <>
      {isShow && card && paymentStatus === GiftProcessStatusEnum.DEFAULT && <div className="gift-container__shedow" />}
      {paymentStatus === GiftProcessStatusEnum.DEFAULT && (
        <div className={`gift-container ${isShow ? " is-shown" : ""}`}>
          {card && (
            <div className="gift-container__close-block">
              <Icon
                className="gift-container__close"
                onClick={() => {
                  setPaymentStatus(GiftProcessStatusEnum.DEFAULT);
                  setIsShowGiftsBlock(false);
                }}
                type={IconTypeEnum.Close}
                size={IconSizeEnum.Size32}
              />
            </div>
          )}

          {card ? (
            <>
              <p className="gift-container__title">Сделайте эксперту подарок </p>
              <div className="gift-container__card">
                <span>Способ оплаты:</span>
                <Icon type={IconTypeEnum.PayMastercard} size={IconSizeEnum.Size20} />
                <span>
                  {cardTypeToString(card?.type ?? "")}
                  {" "}
                  •
                  {card?.number}
                </span>
              </div>
              <div className="gift-container__block-gift">
                {giftsData.map(({ productID, svgPath, name, prace, animationPath }) =>
                  (
                    <div
                      key={productID}
                      onClick={() => {
                        paymentHandler(productID);
                        setPaymentStatus(GiftProcessStatusEnum.IN_PROGRESS);
                        setIsShowGiftsBlock(false);
                        setChoosedAnimationPath(animationPath);
                        setChoosedSvgPath(svgPath);
                      }}
                    >
                      <GiftAnimateComponent
                        svgPath={svgPath}
                        name={name}
                        prace={prace}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <GiftPaymentFailure
              amount={150}
              isShow={isShow}
              setIsShowGiftsBlock={setIsShowGiftsBlock}
              hasSevdCard={!card}
            />
          )}
        </div>
      )}

      {(paymentStatus === GiftProcessStatusEnum.FAILURE
       || paymentStatus === GiftProcessStatusEnum.INSUFFICIENT_BALANCE) && (
       <GiftPaymentFailure
         amount={150}
         isShow={isShow}
         setIsShowGiftsBlock={setIsShowGiftsBlock}
         hasSevdCard={!!card}
         paymentStatus={paymentStatus}
       />
      )}

      {paymentStatus === GiftProcessStatusEnum.SUCCESS && (
        <GiftPaymentSuccess />
      )}

      {paymentStatus === GiftProcessStatusEnum.IN_PROGRESS && choosedAnimationPath && (
        <GiftPaymentInProgress choosedAnimationPath={choosedAnimationPath} />
      )}
    </>
  );
};

export default GiftContainer;
