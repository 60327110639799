import { IconTypeEnum } from "@components/common/icon";

export const MEDIA_CHECK_UI_DATA = {
  video: {
    title: `Камера`,
    icon: IconTypeEnum.Video,
    noCameraItem: {},
    subtext: {
      default: `Выберите пункт <b>Без камеры</b>, если хотите сохранить анонимность`,
      noDevice: `Подключите камеру и перезагрузите страницу`,
      noAccess: `Разрешите доступ к камере`,
    },
  },
  microphone: {
    title: `Микрофон`,
    icon: IconTypeEnum.Microphone,
    subtext: {
      noDevice: `Подключите микрофон и перезагрузите страницу`,
      noAccess: `Разрешите доступ к микрофону`,
      default: ``,
    },
    checkText: `Скажите что-нибудь, и уровень отобразится на шкале`,
  },
  audio: {
    title: `Динамики`,
    icon: IconTypeEnum.Audio,
    button: `Проверить динамики`,
    subtext: {
      noDevice: `Подключите динамики и перезагрузите страницу`,
    },
    checkText: `Нажмите кнопку, и вы услышите звук`,
  },
  endCheckButton: {
    text: `Перейти к консультации`,
    icon: IconTypeEnum.VideoOff,
    subtext: {
      noCamera: `Ваша камера не подключена — эксперт не будет вас видеть. <br>Это не помешает проведению консультации`,
    },
  },
};

export const DEVICES_RELOAD_DEBOUNSING_MS = 4000;
