import React from "react";

import GiftJsonAnimation from "../giftJsonAnimation";

import { GiftAnimateComponentProps } from "./types";

import "./styles.scss";

const GiftAnimateComponent = ({ svgPath, name, prace }: GiftAnimateComponentProps) =>
  (
    <div className="gift-block">
      <GiftJsonAnimation animationPath={svgPath} />
      <h3 className="gift-block__gift-name">{name}</h3>
      {prace && (
      <p className="gift-block__gift-prace">
        {prace}
      </p>
      )}
    </div>
  );
export default GiftAnimateComponent;
