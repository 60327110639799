import React from "react";

import GiftJsonAnimation from "@/components/common/giftJsonAnimation";

import { GiftPaymentInProgressProps } from "./types";

import "./styles.scss";

const GiftPaymentInProgress = ({ choosedAnimationPath }: GiftPaymentInProgressProps) =>
  (
    <>
      <div className="gift-payment-in-progress__shedow" />
      <div className="gift-payment-in-progress">
        <GiftJsonAnimation animationPath={choosedAnimationPath} className="gift-payment-in-progress__animation" />
        <p className="gift-payment-in-progress__text">
          Отправляем подарок...
        </p>
      </div>
    </>
  );
export default GiftPaymentInProgress;
