import { IconTypeEnum } from "@/components/common/icon";

import { protectionSliderDataType } from "./types";

export const protectionSliderData: protectionSliderDataType[] = [
  {
    id: 1,
    text: `Отложите на время \n отвлекающие занятия`,
    icon: IconTypeEnum.Food,
  },
  {
    id: 2,
    text: `Звоните сидя — ходьба \n или лежание ухудшит контакт`,
    icon: IconTypeEnum.Bed,
  },
  {
    id: 3,
    text: `Сфокусируйтесь на своём \n вопросе`,
    icon: IconTypeEnum.Concentration,
  },
  {
    id: 4,
    text: `Удобно оденьтесь \n для комфортного разговора`,
    icon: IconTypeEnum.Dress,
  },
  {
    id: 5,
    text: `Записывайте важное \n в блокнот или на диктофон`,
    icon: IconTypeEnum.Paper,
  },
];
