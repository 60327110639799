import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/gatsby";
import { Helmet } from "react-helmet";

import GlobalContext from "@/contexts/Global/GlobalContext";
import { isNullOrUndefined, logToConsole, retryPromise } from "@/utils/commonUtils";
import Chat from "@components/chat";
import VoxImplantSingleton from "@components/chat/voximplant/VoxImplant";

import { GET_CALL_TOKEN_QUERY } from "../graphql/GET_CALL_TOKEN_QUERY";
import { CallChangeDataType } from "../videoSession/types";

import { loginPostfix } from "./data";
import { VoximplantChatProps } from "./types";

const sdk = VoxImplantSingleton;

const VoximplantChat = (props: VoximplantChatProps) => {
  const {
    enableVideo,
    VIName,
    receiver,
    outOfMoneyTime,
    sessionConnectionChangedCallback,
    startTimestamp,
    endSessionCallback,
    devices,
    isVideoEnabled,
    sessionRefetch,
    paidGifts,
    sessionId,
    receiver: { voxImplantInfo: { userName: receiverVIName = "" } = {} },
  } = props;

  const [isConnected, setIsConnected] = useState(false);
  const [isVideo, setIsVideo] = useState(!!devices.video);
  const [isAudio, setIsAudio] = useState(!!devices.microphone);
  const [isRemoteVideoOn, setIsRemoteVideoOn] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [viKey, setViKey] = useState<string | null>(null);
  const [isViInitialized, setIsViInitialized] = useState<boolean>(false);

  const { setAlertStatus } = useContext(GlobalContext);

  const [getCallToken, {
    loading: callTokenLoading,
    error: callTokenError,
    data: callTokenData,
  }] = useLazyQuery(GET_CALL_TOKEN_QUERY);

  const callChangeCallback = useCallback(
    (data: CallChangeDataType) => {
      switch (true) {
        case !isNullOrUndefined(data.remoteVideo): // todo: probably or undefined
          setIsRemoteVideoOn(!!data.remoteVideo);
          break;
        case !isNullOrUndefined(data.isConnected): // todo: probably or undefined
          setIsConnected(!!data.isConnected);
          sessionConnectionChangedCallback(!!data.isConnected);
          if (data.isConnected) {
            // setAlertStatus("in_progress");//todo: check if correct
          }
          break;
        case !isNullOrUndefined(data.disableButtons):
          setIsButtonDisabled(!!data.disableButtons);
          break;
        default:
          break;
      }
    },
    [sessionConnectionChangedCallback],
  );

  const toggleMicrophone = (isActive: boolean) => { // todo: use useCallback
    setIsAudio(isActive);
    sdk.toggleMicrophone(isActive);
  };

  const toggleCameraCallback = useCallback((toggleCameraResult: boolean) => {
    setIsVideo(toggleCameraResult);
  }, []);

  const toggleCamera = (isActive: boolean) => {
    if (!sdk.isVideoReady) {
      return;
    }
    sdk.toggleCamera(isActive, toggleCameraCallback);
  };

  const endSession = () => {
    sdk.endCall();
    setAlertStatus("ended");
    if (sessionId) {
      endSessionCallback(parseInt(sessionId, 10));
    }
  };

  useEffect(() => {
    sdk.get();
    sdk.setCallback(callChangeCallback);
  }, [callChangeCallback]);

  useEffect(
    () =>
      () => {
        sdk.disconnect();
      },
    [],
  );

  useEffect(() => {
    if (VIName !== null && !isViInitialized) {
      retryPromise(() =>
        sdk.connect(VIName, sessionId, isVideo), { retries: "INFINITELY" })
        .then((key: string | null) => {
          if (key) {
            console.log("Video: viKey", key);
            setViKey(key);
            setIsViInitialized(true);
          }
        })
        .catch((exception: Error) => {
          Sentry.captureException(exception, {
            tags: {
              occurrence: "setViKey",
            },
          });
          logToConsole("key is null", exception);
        });
    }
  }, [VIName, isViInitialized, isVideo, sessionId]);

  useEffect(() => {
    if (viKey) {
      getCallToken({ variables: { key: viKey } });
    }
  }, [getCallToken, viKey]);

  // todo: maybe set some query attempt limit. If exeedes - show user an error
  useEffect(() => {
    if (callTokenError && viKey) {
      getCallToken({ variables: { key: viKey } });
    }
  }, [callTokenError, getCallToken, viKey]);

  // getCallToken query success handler
  useEffect(() => {
    if (callTokenData && !callTokenLoading && !callTokenError) {
      if (VIName !== null) {
        const password = callTokenData.getCallToken;

        sdk.loginWithOneTimeKey(VIName!, password)
          .catch((exception: Error) => {
            Sentry.captureException(exception, {
              tags: {
                occurrence: "loginWithOneTimeKey",
              },
            });
          })
          .then(() =>
            sdk.startCall(devices, `${receiverVIName}${loginPostfix}`, !!devices.video))
          .catch((exception: Error) => {
            Sentry.captureException(exception, {
              tags: {
                occurrence: "startCall",
              },
            });
            logToConsole("startCall NOT run");
          });
      }
    }
  }, [callTokenError, callTokenLoading, callTokenData, VIName, receiverVIName, devices]);

  return (
    <>
      <Helmet>
        <html className="html--full-height" lang="ru" />
        <body className="footer--hide header--hide body--full-height carrot--disable" />
      </Helmet>
      <Chat
        sessionId={sessionId}
        enableVideo={enableVideo}
        isVideo={isVideo}
        noVideoDevice={!isVideoEnabled}
        isRemoteVideoOn={isRemoteVideoOn}
        isAudio={isAudio}
        isButtonDisabled={isButtonDisabled}
        isConnected={isConnected}
        endSessionEvent={endSession}
        toggleMicrophone={toggleMicrophone}
        toggleCamera={toggleCamera}
        receiver={receiver}
        outOfMoneyTime={outOfMoneyTime}
        startTimestamp={startTimestamp}
        sessionRefetch={sessionRefetch}
        paidGifts={paidGifts}
      />
    </>
  );
};

export default VoximplantChat;
