import * as Sentry from "@sentry/gatsby";
import * as VoxImplant from "voximplant-websdk";
import { Call } from "voximplant-websdk/Call/Call";

import { retryPromise } from "@/utils/commonUtils";
import { isBrowser } from "@/utils/env";
import { loginPostfix } from "@components/chat/voximplant/data";

export default class VoxImplantSingleton {
  public static isVideoReady: boolean = false;

  private static instance: any;

  private static VI: any = isBrowser() ? VoxImplant.getInstance() : null;

  private static currentCall: Call | null = null;

  private static customData: string;

  private static isVideo: boolean;

  private static configObj = {
    localVideoContainerId: "video-local", // won't be needed if we start using MediaRendererAdded
    remoteVideoContainerId: "video-remote", // not needed because we render video by ourselves
    micRequired: true, // force microphone/camera access request
    // videoSupport: true, // deprecated
    progressTone: true, // play progress tone
    showDebugInfo: false,
  };

  private static eventCallback: (data: any) => void;

  private static isHangup: boolean = false;

  public static get() {
    if (!VoxImplantSingleton.instance) {
      VoxImplantSingleton.instance = new VoxImplantSingleton();
    }
    return VoxImplantSingleton.instance;
  }

  public static setCallback(callback: (data: any) => void) {
    this.eventCallback = callback;
  }

  public static async disconnect() {
    try {
      await this.VI.disconnect();
    } catch {
      console.log("Connection failed!");
    }
  }

  public static async connect(login: string, customData: string, isVideo: boolean): Promise<string | null> {
    if (this.VI === null) {
      return null;
    }

    this.customData = customData;
    this.isVideo = isVideo;

    await this.init();

    let viKey: string | null = null;

    return retryPromise(() =>
      this.VI.connect(), {
      retries: "INFINITELY",
    })
      .then(() =>
        this.VI.requestOneTimeLoginKey(this.fullLogin(login)))
      .then((e: any) => {
        if (e.result) {
          console.log("authorization was successful - can make/receive calls now");
        } else if (e.code == 302 && e.key) {
          viKey = e.key;
        }

        if (viKey) {
          return viKey;
        }

        throw new Error("requestOneTimeLoginKey failed");
      })
      .catch((exception: Error) => {
        Sentry.captureException(exception, {
          tags: {
            occurrence: "Logging with one time key",
          },
        });
        throw new Error("VI connect failed");
      });
  }

  public static loginWithOneTimeKey(login: string, password: string) {
    return this.VI.loginWithOneTimeKey(this.fullLogin(login), password);
  }

  public static async startCall(
    devices: { video?: string; audio?: string; microphone?: string },
    receiver?: string,
    isUseCamera = true,
  ) {
    const {
      video,
      audio,
      microphone,
    } = devices;
    await VoxImplant.Hardware.CameraManager.get()
      .setDefaultVideoSettings({
        cameraId: video || undefined,
      });
    this.isVideoReady = true;

    await VoxImplant.Hardware.AudioDeviceManager.get()
      .setDefaultAudioSettings({
        inputId: microphone || undefined,
        outputId: audio || undefined,
      });

    this.isHangup = false;

    if (this.currentCall && this.currentCall.active()) {
      this.currentCall.hangup();
      this.isHangup = true;

      this.currentCall = null;
      return;
    }

    if (!receiver) {
      this.isHangup = true;
      this.VI.showLocalVideo(false); // disable local stream
      return;
    }
    // start the call
    this.currentCall = this.VI.call({
      number: receiver,
      video: {
        sendVideo: isUseCamera,
        receiveVideo: true,
      },
    });

    this.addEvents(this.currentCall!);
  }

  public static endCall() {
    this.isHangup = false;

    if (this.currentCall && this.currentCall.active()) {
      this.currentCall.hangup();
      this.isHangup = true;

      this.currentCall = null;
      this.isVideoReady = false;
      return;
    }

    this.isHangup = true;
    this.isVideoReady = false;
  }

  public static toggleMicrophone(isActive: boolean) {
    this.currentCall && isActive
      ? this.currentCall.unmuteMicrophone()
      : this.currentCall?.muteMicrophone();
  }

  public static toggleCamera(isActive: boolean, callback: (toggleCameraResult: boolean) => void) {
    if (!this.currentCall) {
      callback(false);
      return;
    }
    this.isVideoReady = false;
    this.currentCall!.sendVideo(isActive)
      .then((response) => {
        if (response.result) {
          this.isVideoReady = true;
          callback(isActive);
        } else {
          callback(!isActive); // todo: better to return call video status
        }
      });
  }

  private static fullLogin(login: string) {
    return `${login}${loginPostfix}`;
  }

  private static setCallbackParams(params: {}) {
    if (VoxImplantSingleton.eventCallback) {
      VoxImplantSingleton.eventCallback(params);
    }
  }

  private static addEvents(target: Call) {
    // subscribe to the events if necessary

    const addEndpointEvents = (call: Call) => {
      call.on(VoxImplant.CallEvents.EndpointAdded, (e: any) => {
        this.setCallbackParams({ remoteVideo: false });

        // subscribe to the remote media added event
        e.endpoint.on(VoxImplant.EndpointEvents.RemoteMediaAdded, (e: any) => {
          // point to the remote video container id
          const container = document.getElementById("video-remote");
          e.mediaRenderer.render(container);

          e.mediaRenderer.element.localName === "video"
          && this.setCallbackParams({ remoteVideo: e.mediaRenderer.enabled });
        });

        e.endpoint.on(VoxImplant.EndpointEvents.RemoteMediaRemoved, () => {
          this.setCallbackParams({ remoteVideo: false });
        });
      });
    };

    target.on(VoxImplant.CallEvents.Connected, () => {
      this.setCallbackParams({ isConnected: true });
      this.setCallbackParams({ disableButtons: false });
    });

    this.VI.on(VoxImplant.CallEvents.Disconnected, () => {
      this.setCallbackParams({ isConnected: false });
    });

    // use only with "forwardCallToUserDirect"
    if (target.settings.incoming) {
      addEndpointEvents(target);
    } else {
      target.on(VoxImplant.CallEvents.Connected, (e: any) => {
        addEndpointEvents(e.call);
      });
    }

    /** isHangup is needed to turn off the video when the call is finished */
    target.on(VoxImplant.CallEvents.Disconnected, () => {
      this.isHangup = true;
    });

    target.on(VoxImplant.CallEvents.StateUpdated, (data: any) => {
      // if your companion finished the call
      if (data.new === "ENDED") {
        this.setCallbackParams({ remoteVideo: false });
        this.setCallbackParams({ isConnected: false });
        this.currentCall = null;
      }
    });

    target.on(VoxImplant.CallEvents.Updated, () => {
      // console.log("Call Updated!");
    });

    target.on(VoxImplant.CallEvents.Failed, (e: any) => {
      this.setCallbackParams({ disableButtons: false });
    });
  }

  private static async init() {
    try {
      if (this.VI.alreadyInitialized) {
        await this.VI.showLocalVideo(false);
      } else {
        await this.VI.init(this.configObj);
      }
      this.VI.showLocalVideo(true);

      this.VI.on(VoxImplant.Events.IncomingCall, (e: any) => {
        this.isHangup = false;
        if (this.currentCall) {
          // todo: what is wrong with u?
          // this.currentCall.decline()
        }

        this.currentCall = e.call;

        if (this.currentCall) {
          this.currentCall!.answer(this.customData, undefined, {
            sendVideo: this.isVideo,
            receiveVideo: true,
          });
          this.addEvents(this.currentCall);
        }
      });

      const streamManager = VoxImplant.Hardware.StreamManager.get();

      streamManager.on(VoxImplant.Hardware.HardwareEvents.MediaRendererRemoved, (e) => {
        // console.log("MediaRendererRemoved", e);
      });

      streamManager.on(VoxImplant.Hardware.HardwareEvents.BeforeMediaRendererRemoved, () => {
        // console.log("BeforeMediaRendererRemoved");
      });

      streamManager.on(VoxImplant.Hardware.HardwareEvents.MediaRendererUpdated, async () => {
        if (this.isHangup) {
          await this.VI.showLocalVideo(false); // disable local stream
        }
      });
    } catch (e) {
      console.log("SDK init failure!");
    }
  }
}
