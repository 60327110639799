import React, { useContext } from "react";

import {
  getActiveSession_getActiveSession_expert,
} from "@layout/sessionController/graphql/__generated__/getActiveSession";

import ProtectionSlider from "@/components/chat/protectionSlider";
import UserInfo from "@/components/common/userInfo";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import { UserAvatar } from "@components/common/userAvatar";
import { UserAvatarTypeEnum } from "@components/common/userAvatar/types";

import { SessionWaintingBlockProps } from "./types";
import { inlineSkills } from "./utils";

// import "./styles.scss";

const SessionWaitingBlock = (props: SessionWaintingBlockProps) => {
  const {
    text,
    subtextTop,
    subtextBottom,
    isRemoteVideoOn, // todo: ask Marina if needed
    isConnected, // maybe refactor this
    receiver,
    showCarousel = false,
  } = props;

  const { isExpert } = useContext(GlobalContext);

  const title = isConnected
    ? receiver.name || receiver?.id?.toString()
    : text;

  const inProgressSubtitle = isExpert
    ? inlineSkills((receiver as getActiveSession_getActiveSession_expert).skills ?? [])
    : "";

  const subtitle = isConnected
    ? inProgressSubtitle
    : subtextTop;

  const subtitleNewLine = isConnected
    ? inProgressSubtitle
    : subtextBottom;

  return (
    <UserInfo
      avatar={(
        <UserAvatar
          mobileImage={receiver.mobileImage}
          desktopImage={receiver.desktopImage}
          name={receiver.name}
          userId={receiver?.id as number}
          type={isRemoteVideoOn ? UserAvatarTypeEnum.CallSmall : UserAvatarTypeEnum.Call}
        />
      )}
      title={title}
      subtitle={subtitle}
      subtitleNewLine={subtitleNewLine}
      isConnecting={!isConnected}
      className={isRemoteVideoOn ? "user-info--small" : ""}
    >
      <>
        {!isRemoteVideoOn && isConnected && (
          <div className="chat__receiver--video-off">
            <Icon type={IconTypeEnum.VideoOff} size={IconSizeEnum.Size24} />
          </div>
        )}
        {showCarousel && <ProtectionSlider />}
      </>
    </UserInfo>
  );
};

export default SessionWaitingBlock;
