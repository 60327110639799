import React from "react";

import { Currency } from "@/autoGeneratedGlobalTypes";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";

import { GiftProcessStatusEnum } from "../../types";

import { GiftPaymentFailureProps } from "./types";

import "./styles.scss";

const GiftPaymentFailure = ({
  isShow, amount, setIsShowGiftsBlock, hasSevdCard, paymentStatus,
}: GiftPaymentFailureProps) =>
  (
    <div className={`gift-container ${isShow && " is-shown"}`}>
      <div className="gift-container__close-block">
        <Icon
          className="gift-container__close"
          onClick={() =>
            setIsShowGiftsBlock(false)}
          type={IconTypeEnum.Close}
          size={IconSizeEnum.Size32}
        />
      </div>

      <div className="gift-payment-failure">
        <div className="gift-payment-failure__failure-circle">
          <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size24} />
        </div>

        {paymentStatus === GiftProcessStatusEnum.FAILURE && (
        <div className="gift-payment-failure__failure-text">
          Платёж
            {" "}
          {amount ? `на ${priceToString(amount)} ${currencyToString(Currency.RUB)}` : ""}
          <br />
          отклонён
        </div>
        )}

        {paymentStatus === GiftProcessStatusEnum.INSUFFICIENT_BALANCE && (
        <div className="gift-payment-failure__failure-text">
          Недостаточно средств. Пополните карту
          <br />
          или воспользуйтесь другой.
        </div>
        )}

        {hasSevdCard
          && paymentStatus !== GiftProcessStatusEnum.FAILURE
            && paymentStatus !== GiftProcessStatusEnum.INSUFFICIENT_BALANCE
              && (
                <div className="gift-payment-failure__failure-text">
                  Для покупки подарка
                  {" "}
                  <br />
                  {" "}
                  привяжите карту в личном кабинете
                </div>
              )}
      </div>
    </div>
  );

export default GiftPaymentFailure;
