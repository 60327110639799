import React from "react";

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Icon, IconSizeEnum } from "@/components/common/icon";

import { protectionSliderData } from "./constants";
import { protectionSliderDataType } from "./types";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
import "./styles.scss";

const ProtectionSlider = () =>
  (
    <Swiper
      loop
      direction="horizontal"
      autoplay={{
        delay: 6000,
      }}
      modules={[Autoplay]}
      className="protection-slider"
    >
      {protectionSliderData.map((carouselItem: protectionSliderDataType) =>
        (
          <SwiperSlide className="protection-slider__slide-element" key={carouselItem.id}>
            <Icon type={carouselItem.icon} size={IconSizeEnum.Size32} />
            <span className="protection-slider__description">
              {carouselItem.text}
            </span>
          </SwiperSlide>
        ))}
    </Swiper>
  );

export default ProtectionSlider;
