import React, { useEffect, useState } from "react";

import { calculateTimerValue } from "@components/chat/Duration/utils";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import Timer from "@components/common/Timer";

import { END_SESSION_REMINDER_SECONDS } from "./constants";
import { DurationProps } from "./types";
import { isSessionEndsSoon } from "./utils";

const Duration = ({
  timestamp = new Date().getTime(),
  isReverse = false,
  isPaused = false,
  sessionEndsSoonCallback,
}: DurationProps) => {
  const [currentTimeValue, setCurrentTimeValue] = useState<number>(calculateTimerValue(timestamp));
  useEffect(() => {
    if (sessionEndsSoonCallback) {
      sessionEndsSoonCallback(isSessionEndsSoon(currentTimeValue));
    }
  }, [currentTimeValue, isReverse]);

  return (
    <div
      className={`chat__duration${
        isReverse && Math.abs(currentTimeValue) < END_SESSION_REMINDER_SECONDS
          ? " chat__duration--warning"
          : ""
      }
      ${isReverse && !isSessionEndsSoon(currentTimeValue) && " chat__duration--hidden"}`}
    >
      <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size24} />
      <Timer
        timestamp={timestamp}
        isPaused={isPaused}
        valueCallback={(value) =>
          setCurrentTimeValue(value)}
      />
    </div>
  );
};
export default Duration;
