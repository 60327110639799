import bottleActive from "../../../../static/gift/jsonActiveAnimations/bottle_active.json";
import crystalActive from "../../../../static/gift/jsonActiveAnimations/crystal_active.json";
// import hatActive from "../../../../static/gift/jsonActiveAnimations/hat_active.json";
import hatActiveNew from "../../../../static/gift/jsonActiveAnimations/hat_active_new.json";

// предварительный показ подарков
import bottleSVG from "../../../../static/gift/jsonNotActiveAnimations/bottle_not_active.json";
import crystalSVG from "../../../../static/gift/jsonNotActiveAnimations/crystal_not_active.json";
// import hatSVG from "../../../../static/gift/jsonNotActiveAnimations/hat_not_active.json";
import hatSVGNew from "../../../../static/gift/jsonNotActiveAnimations/hat_not_active_new.json";

interface Igifts {
  productID:number,
  svgPath: any,
  animationPath: any
  name :string
  prace: string
}

export const giftsData:Igifts[] = [
  { productID: 1,
    svgPath: hatSVGNew,
    name: `Шляпa \nволшебника`,
    prace: "99 ₽",
    animationPath: hatActiveNew,
  },
  { productID: 2,
    svgPath: crystalSVG,
    name: "Магический \nкристалл",
    prace: "490 ₽",
    animationPath: crystalActive,
  },
  { productID: 3,
    svgPath: bottleSVG,
    name: "Зелье \nдолгожития",
    prace: "690 ₽",
    animationPath: bottleActive,
  },
];
