import { gql } from "@apollo/client";

// todo: implement state in API
export const MAKE_PAYMENT_WITH_SAVED_CARD = gql`
  mutation makePaymentWithSavedCardFromProfile($amount: Int!, $cardID: Int!, $location: PaymentLocation!, $relatedID: Int!) {
    makePaymentWithSavedCard(input: { amount: $amount, cardID: $cardID, location: $location, relatedID: $relatedID }) {
      id
      amount {
        amount
        currency
      }
      state
      resultCode
      errorValue
      datetime
      card {
        id
        number
        cardHolder
        type
        expiryMonth
        expiryYear
        isDefault
      }
    }
  }
`;
