import React from "react";

import GiftJsonAnimation from "@/components/common/giftJsonAnimation";

import { giftsData } from "../../../constants";

import { GiftNotificationProps } from "./types";

const GiftNotification = ({ giftForExpert }: GiftNotificationProps) =>
  (
    <>
      <div className="chat__expert-gift-history-block">
        {giftForExpert.length && (
        <p className="chat__expert-gift-notification">
          Вам подарили
          {" "}
          {giftForExpert[0]?.product.name}
        </p>
        )}

      </div>
      {giftsData?.map((giftItem) => {
        if (giftItem.productID === giftForExpert[0]?.product.id) {
          return <GiftJsonAnimation animationPath={giftItem?.animationPath} className="chat__notification-animation" />;
        }
        return null;
      })}
    </>
  );

export default GiftNotification;
