import React, { useCallback, useEffect, useRef, useState } from "react";

import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";

import { GiftButtonProps } from "./types";

import "./styles.scss";

const GiftButton = ({ startTimestamp = Date.now(), clientGaveFirstGift }: GiftButtonProps) => {
  const [currentTimeValue, setCurrentTimeValue] = useState<number>(0);
  const sessionDurationTimer = useRef<NodeJS.Timer | null>(null);
  const [firstTwoMinutesPassed, setFirstTwoMinutesPassed] = useState<boolean>(false);
  const [showGiftTooltip, setShowGiftTooltip] = useState(false);

  useEffect(() => {
    if (clientGaveFirstGift) {
      sessionDurationTimer.current = null;
      setShowGiftTooltip(false);
    }
  }, [clientGaveFirstGift]);

  const startInterval = useCallback((intervalDuration: number) =>
    setInterval(() => {
      setCurrentTimeValue((oldValue) => {
        const newValue = oldValue + 1;

        // Check if newValue is equal to the specified intervalDuration
        if (newValue === intervalDuration) {
          setShowGiftTooltip(true);

          // Clear the interval when showGiftTooltip is true
          clearInterval(sessionDurationTimer.current!);
          sessionDurationTimer.current = null;
          setCurrentTimeValue(0);

          // Set a timeout to change showGiftTooltip back to false after 10 seconds
          setTimeout(() => {
            setFirstTwoMinutesPassed(true);
            setShowGiftTooltip(false);
            // Restart the interval with 300 seconds after the timeout
            if (!clientGaveFirstGift) {
              sessionDurationTimer.current = startInterval(300);
            }
          }, 30000);
        }

        return newValue;
      });
    }, 1000), [clientGaveFirstGift]);

  useEffect(() => {
    if (startTimestamp !== null && sessionDurationTimer.current === null && !clientGaveFirstGift) {
    // Start the interval only if startTimestamp has a value
      sessionDurationTimer.current = startInterval(firstTwoMinutesPassed ? 300 : 90);
    }

    // Clear the interval when the component unmounts or when startTimestamp becomes null
    return () => {
      if (sessionDurationTimer.current !== null) {
        clearInterval(sessionDurationTimer.current);
        sessionDurationTimer.current = null;
      }
    };
  }, [firstTwoMinutesPassed, startInterval, startTimestamp, clientGaveFirstGift]);

  return (
    <div className="gift-button-container">
      {showGiftTooltip
        ? (
          <Tooltip
            text="Сделать &nbsp;подарок &nbsp;эксперту"
            position={TooltipPositionEnum.Bottom}
            isAlwaysShown
            minWidth="84"
          >
            <div className="gift-button">
              <img src="./gift/GiftBoxActive.gif" alt="giftBox" />
            </div>
          </Tooltip>
        ) : (
          <div className="gift-button">
            <img src="./gift/GiftBoxBasic.gif" alt="giftBox" />
          </div>
        )}
    </div>
  );
};

export default GiftButton;
