import React from "react";

export type GiftContainerProps = {
  sessionId: string;
  isShow: boolean;
  setIsShowGiftsBlock : (show: boolean) => void;
  setClientGiftHistory: React.Dispatch<React.SetStateAction<string[]>>
  clientGiftHistory: string[]
}

export enum GiftProcessStatusEnum {
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE"
}
