import React, { useEffect, useState } from "react";

import { Dropdown, DropdownSizeEnum, DropdownValue } from "@/components/common/dropdown";
import Loader from "@/components/common/loader";
import { MediaDeviceType } from "@components/chat/mediaCheck/types";
import { Icon, IconSizeEnum } from "@components/common/icon";

const MediaItem = (props: MediaDeviceType) => {
  const {
    mediaList,
    selectedMediaId,
    name = "",
    icon,
    onChangeMedia,
    children,
    subtext,
    disabled = false,
    error = false,
    loading = false,
  } = props;

  const [mediaListOptions, setMediaListOptions] = useState<DropdownValue[]>([]);

  useEffect(
    () => {
      setMediaListOptions((prevVal) => {
        const newVal = mediaList.map((item) =>
          ({
            value: item.deviceId,
            label: item.label,
          }
          )) ?? [];
        if (JSON.stringify(prevVal) !== JSON.stringify(newVal)) {
          return newVal;
        }
        return prevVal;
      });
    },
    [mediaList],
  );

  // todo: debug onClick on speakers dropdown when all permissions denied
  const onChange = (selectedOption: DropdownValue) => {
    onChangeMedia(selectedOption.value);
  };

  return (
    <div className="media-check__section media">
      <div className="media__info">
        {icon && (
          <div className="media__icon">
            <Icon type={icon} size={IconSizeEnum.Size24} />
          </div>
        )}
        {name && <div className="h5">{name}</div>}
      </div>
      <div className="media__list-container">
        {loading && (
          <Loader />
        )}
        {!loading && !!mediaListOptions.length && (
          <>
            <div className="media__list">
              <Dropdown
                value={mediaListOptions.find((option) =>
                  option.value === selectedMediaId)}
                options={mediaListOptions}
                onChange={onChange}
                disabled={disabled}
                size={DropdownSizeEnum.Small}
                error={error}
                optionsUpdateAnimationIsOn
                loading={loading}
              />
            </div>
            {!!subtext && (
              <div className="media__subtext" dangerouslySetInnerHTML={{ __html: subtext }} />
            )}
          </>
        )}
        {!loading && !mediaListOptions.length && (
          <>
            <div className="media__list">Устройств не обнаружено</div>
            {!!subtext && (
              <div className="media__subtext" dangerouslySetInnerHTML={{ __html: subtext }} />
            )}
          </>
        )}
      </div>
      <div className="media__special">{children}</div>
    </div>
  );
};

export default MediaItem;
