import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { UtmAnalysisEventEnum, useManageUtmData } from "@/hooks/useManageUtmData";
import { marketingCallStartedHandler, marketingSessionStartedHandler } from "@/marketing/utils";
import MediaCheck from "@components/chat/mediaCheck";
import VoximplantChat from "@components/chat/voximplant";

import type { endSession } from "../graphql/__generated__/endSession";
import { getProductHistory, getProductHistoryVariables, getProductHistory_getProductHistory } from "../graphql/__generated__/getProductHistory";
import type {
  getSession,
  getSessionVariables,
  getSession_getSession_client,
  getSession_getSession_expert,
} from "../graphql/__generated__/getSession";
import { END_SESSION } from "../graphql/END_SESSION";
import { GET_PRODUCT_HISTORY } from "../graphql/GET_PRODUCT_HISTORY";
import { GET_SESSION } from "../graphql/GET_SESSION";

import {
  AWAITING_FOR_CONNECTION_TIMER_PERIOD_MS,
  EXPERT_GET_GIFTS_INFO_PERIOD_MS,
  EXPERT_UPDATE_SESSION_INFO_PERIOD_MS,
} from "./constants";
import { getSessionIdFromUrl } from "./utils";

const ChatContainer = () => {
  const sessionId = getSessionIdFromUrl();

  const [isSessionShown, setIsSessionShown] = useState(false);
  const [isSessionInProgress, setIsSessionInProgress] = useState(false);
  const [isSessionFinished, setIsSessionFinished] = useState(false);
  const [VIName, setVIName] = useState<string>();
  const [
    receiver,
    setReceiver,
  ] = useState<getSession_getSession_expert | getSession_getSession_client>();
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [paidGifts, setPaidGifts] = useState<(getProductHistory_getProductHistory | null)[]>([] || null);
  const [devices, setDevices] = useState({
    video: "",
    audio: "",
    microphone: "",
  });

  const getSessionTimerRef = useRef<NodeJS.Timer | null>(null);
  const getGiftsTimerRef = useRef<NodeJS.Timer | null>(null);

  const {
    setIsAlertShown,
    setIsGetActiveSessionLoopRunning,
    setAlertStatus,
    isExpert,
  } = useContext(GlobalContext);
  const {
    isUserLoggedIn,
    userID,
  } = useContext(UserContext);

  const { saveUtmData } = useManageUtmData();

  const [
    getSession,
    {
      loading: getSessionloading,
      error: getSessionError,
      data: getSessionData,
      refetch: getSessionRefetch,
    },
  ] = useLazyQuery<getSession, getSessionVariables>(GET_SESSION, { fetchPolicy: "network-only" });

  const [getProductHistoryHandler, {
    data: productHistoryData, loading: productHistoryLoading, error: productHistoryError,
  }] = useLazyQuery< getProductHistory, getProductHistoryVariables>(GET_PRODUCT_HISTORY);

  const [endSession, {
    loading: endSessionIsLoading,
    error: endSessionError,
    data: endSessionResponse,
  }] = useMutation<endSession>(END_SESSION, { fetchPolicy: "network-only" });

  const endCheckCallback = ({
    video = "",
    audio = "",
    microphone = "",
  }, enableVideo = true) => {
    if (isSessionInProgress) {
      // setAlertStatus("in_progress"); //todo: check if this is ok
      navigate("/");
      return;
    }
    setIsSessionShown(true);
    setIsVideoEnabled(enableVideo);
    setDevices((prevState) =>
      ({
        ...prevState,
        video,
        audio,
        microphone,
      }));
    if (!isExpert) {
      marketingCallStartedHandler();
      saveUtmData({
        eventType: UtmAnalysisEventEnum.CallStarted,
        sessionID: parseInt(sessionId || "0", 10),
      });
    }
  };

  useEffect(() => {
    if (productHistoryData && !productHistoryLoading && !productHistoryError) {
      setPaidGifts(productHistoryData.getProductHistory);
    }
  }, [productHistoryData, productHistoryError, productHistoryLoading]);

  const endSessionCallback = (sessionID: number) => { // todo: use useCallback on refactoring
    endSession({ variables: { sessionID } });
  };

  const getGiftsBySessionId = useCallback(() => {
    getProductHistoryHandler({ variables: { filter: { sessionID: parseInt(sessionId, 10) } }, fetchPolicy: "cache-and-network" });
  }, [getProductHistoryHandler, sessionId]);

  const stopGetSessionTimer = useCallback(() => {
    if (getSessionTimerRef.current !== null) {
      clearInterval(getSessionTimerRef.current);
      getSessionTimerRef.current = null;
    }
  }, []);

  const stopGiftsTimer = () => {
    if (getGiftsTimerRef.current !== null) {
      clearInterval(getGiftsTimerRef.current);
      getGiftsTimerRef.current = null;
    }
  };

  const restartGetSessionTimer = useCallback((periodMs: number) => {
    stopGetSessionTimer();
    getSession({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
    getSessionTimerRef.current = setInterval(
      () => {
        getSession({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
      },
      periodMs,
    );
  }, [getSession, sessionId, stopGetSessionTimer]);

  const runGiftsTimer = useCallback((periodMs: number) => {
    stopGiftsTimer();
    getGiftsBySessionId();
    getGiftsTimerRef.current = setInterval(() =>
      getGiftsBySessionId(), periodMs);
  }, [getGiftsBySessionId]);

  // Restarts getSession timer accorging to VI session connection change
  const sessionConnectionChangedCallback = useCallback((isConnected: boolean) => {
    // console.log("?????????????sessionConnectionChangedCallback");
    if (isConnected) {
      if (isExpert) {
        // Because some info (i.e. user balance) may change durring session,
        // expert will be getting session data with EXPERT_UPDATE_SESSION_INFO_PERIOD_MS period
        restartGetSessionTimer(EXPERT_UPDATE_SESSION_INFO_PERIOD_MS);
        runGiftsTimer(EXPERT_GET_GIFTS_INFO_PERIOD_MS);
      } else {
        // Turning off getting session data for client
        stopGetSessionTimer();
        stopGiftsTimer();
        // todo: check if sessionConnectionChangedCallback is called on successful connection
        // todo: othervise move marketingSessionStartedHandler to a correct place
        marketingSessionStartedHandler();
      }
    } else {
      restartGetSessionTimer(AWAITING_FOR_CONNECTION_TIMER_PERIOD_MS);
      runGiftsTimer(EXPERT_GET_GIFTS_INFO_PERIOD_MS);
    }
  }, [isExpert, restartGetSessionTimer, runGiftsTimer, stopGetSessionTimer]);

  // Sets getSession timer
  useEffect(() => {
    if (isUserLoggedIn) {
      // setIsAlertShown(false); //todo: check if this is ok
      restartGetSessionTimer(AWAITING_FOR_CONNECTION_TIMER_PERIOD_MS);
      runGiftsTimer(EXPERT_GET_GIFTS_INFO_PERIOD_MS);
    } else {
      setAuthParamToURL(window.location, FormTypeEnum.Login);
    }
    return () => {
      stopGetSessionTimer();
      stopGiftsTimer();
      // if (isUserLoggedIn) {
      //   setIsAlertShown(!isSessionFinished); // todo: maybe uncomment
      // }
    };
  }, [isUserLoggedIn, restartGetSessionTimer, runGiftsTimer, stopGetSessionTimer]);

  // Turning off getActiveSession query while session is running
  useEffect(() => {
    setIsGetActiveSessionLoopRunning(false);
    return () => {
      setIsGetActiveSessionLoopRunning(isUserLoggedIn);
    };
  }, [isUserLoggedIn, setIsGetActiveSessionLoopRunning]);

  // todo: delete this test effect if not needed
  useEffect(() => {
    //
  }, []);

  // getSession response handler
  useEffect(() => {
    if (getSessionError) {
      // todo: log to Sentry
    }
    if (/* todo: check if userID needed  && */
      getSessionData && !getSessionloading && !getSessionError) {
      const {
        client,
        expert,
        status,
        statistics: {
          durationInMinutes,
          totalPrice,
        },
      } = getSessionData.getSession;
      // setAlertStatus(status); //todo: check if this is ok
      // todo: probably leave this to a first query instead of one with waiting status
      if (status === SessionStatus.waiting) {
        // todo: use getSessionData.getMyProfile.isExpert like in TextChatContainer
        if (client.id === userID) {
          setVIName(client.voxImplantInfo.userName);
          if (!receiver) {
            setReceiver(expert);
          }
        } else {
          setVIName(expert.voxImplantInfo.userName);
          if (!receiver) {
            setReceiver(client);
          }
        }
      } else if (status === SessionStatus.in_progress) {
        setIsSessionInProgress(true);
      } else {
        navigate("/chat/review", {
          state: {
            receiver,
            durationInMinutes,
            totalPrice,
            sessionID: sessionId,
            sessionType: SessionType.VIDEO_CALL,
            isExpert,
          },
        });
      }
    }
  }, [
    userID,
    getSessionloading,
    getSessionError,
    getSessionData,
    // setAlertStatus,
    receiver,
    sessionId,
    isExpert,
  ]);

  // End session success handler
  useEffect(() => {
    if (endSessionResponse && !endSessionIsLoading && !endSessionError) {
      const {
        durationInMinutes,
        totalPrice,
      } = endSessionResponse.endSession;

      // debugger;
      navigate("/chat/review", {
        state: {
          receiver,
          durationInMinutes,
          totalPrice,
          sessionID: sessionId,
          sessionType: SessionType.VIDEO_CALL,
          isExpert,
        },
      });
    }
    // todo: check how affects
  }, [endSessionIsLoading, endSessionError, endSessionResponse, receiver, isExpert, sessionId]);

  // Effect for cases when session has been terminated unexpectedly - todo: check if needed
  useEffect(() => {
    if (isSessionShown && VIName && receiver && !endSessionResponse) {
      const sessionIDFromURL = getSessionIdFromUrl();

      // todo: check if session ID is valid somewhere else
      // switch (true) {
      //   case sessionID === null: // сессия прервалась
      //   case sessionID !== sessionIDFromURL: // id сессии не соответствует URL
      //     navigate("/");
      //     break;
      //   default: break;
      // }
    }
  }, [VIName, endSessionResponse, isSessionShown, receiver]); // todo: check how affects

  // Turning off the alert
  useEffect(() => {
    setIsAlertShown(false);

    return () => {
      setIsAlertShown(true);
    };
  }, [setIsAlertShown]);

  const {
    startTimestamp,
    outOfMoneyTime,
  } = getSessionData?.getSession || {};

  // console.log("???????isSessionShown", isSessionShown);
  // console.log("???????VIName", VIName);
  // console.log("???????receiver", receiver);

  return (
    <>
      <Helmet>
        <body className="footer--hide header--hide body--full-height" />
      </Helmet>
      {isSessionShown ? (
        VIName && receiver && (
          <VoximplantChat
            enableVideo // todo: refactor according to the fact it's always true first
            VIName={VIName}
            devices={devices}
            receiver={receiver}
            sessionConnectionChangedCallback={sessionConnectionChangedCallback}
            outOfMoneyTime={outOfMoneyTime}
            endSessionCallback={endSessionCallback}
            startTimestamp={startTimestamp}
            isVideoEnabled={isVideoEnabled}
            sessionRefetch={getSessionRefetch}
            paidGifts={paidGifts}
            sessionId={sessionId}
          />
        )
      ) : (
        <MediaCheck endCheckCallback={endCheckCallback} />
      )}
    </>
  );
};

export default ChatContainer;
