import { gql } from "@apollo/client";

export const GET_USER_CARDS_FROM_SESSION = gql`
  query getUserCardsFromSession {
    getUserCards {
      id
      number
      type
      isDefault
    }
  }
`;
