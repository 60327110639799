import React, { useEffect, useState } from "react";

import { VOLUME_LEVEL_INTERVAL_MS, VOLUME_LEVEL_SEGMENT_COUNT } from "./constants";
import { VolumeLevelProps } from "./types";

import "./styles.scss";

const VolumeLevel = ({ analyser }: VolumeLevelProps) => {
  const [level, setVolumeLevel] = useState<number>(0);

  useEffect(() => {
    if (!analyser) {
      return () => {};
    }

    const volumes = new Uint8Array(analyser.frequencyBinCount);

    const volumeCallback = () => {
      analyser.getByteFrequencyData(volumes);
      let volumeSum = 0;
      for (let i = 0; i < analyser.frequencyBinCount; i += 1) {
        volumeSum += volumes[i];
      }
      const averageVolume = volumeSum / volumes.length;
      setVolumeLevel((averageVolume * 100) / 150);
    };

    const micBarTimer = setInterval(volumeCallback, VOLUME_LEVEL_INTERVAL_MS);

    return () => {
      clearInterval(micBarTimer);
    };
  }, [analyser]);

  return (
    <div className="volume-level">
      {Array.from(
        { length: VOLUME_LEVEL_SEGMENT_COUNT },
        (v, i) =>
          i,
      ).map((numValue) =>
        (
          <div
            className={`volume-level__item ${level > (100 / VOLUME_LEVEL_SEGMENT_COUNT) * numValue ? "checked" : ""}`}
            key={numValue}
          />
        ))}
    </div>
  );
};

export default VolumeLevel;
