import { ApolloQueryResult } from "@apollo/client";

export type FastPaymentProps = {
  isSessionEndsSoon: boolean;
  expertRate: number;
  sessionRefetch: () => Promise<ApolloQueryResult<any>>;
  setIsShowGiftsBlock: (show: boolean) => void
  isShowGiftsBlock: boolean
  sessionId: string;
};

export enum FastPaymentStatusEnum {
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}
