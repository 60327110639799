import React from "react";

import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import "./styles.scss";

const GiftPaymentSuccess = () =>
  (
    <>
      <div className="gift-success__shadow-confetti" />
      <div className="gift-success">
        <p className="gift-success__text">
          <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size48} />
          Эксперт получил
          {" "}
          <br />
          ваш подарок
        </p>
        <Confetti />
      </div>
    </>
  );

export default GiftPaymentSuccess;
