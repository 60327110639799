import { useContext, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";

import { PaymentError, PaymentLocation } from "@/autoGeneratedGlobalTypes";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";

import {
  makePaymentWithSavedCardFromProfile,
  makePaymentWithSavedCardFromProfileVariables,
} from "./graphql/__generated__/makePaymentWithSavedCardFromProfile";
import { MAKE_PAYMENT_WITH_SAVED_CARD } from "./graphql/MAKE_PAYMENT_WITH_SAVED_CARD";
import { UsePayWithSavedCardProps, UsePayWithSavedCardType } from "./types";

/**
 * Returns function for creating payment by card ID and GQL loading status
 */
const usePayWithSavedCard = ({
  onSuccess = () => {},
  onFail = () => {},
  onComplete = () => {},
}: UsePayWithSavedCardProps): UsePayWithSavedCardType => {
  const {
    isUserLoggedIn,
    setUnfinishedPaymentId,
    setPaymentFinishedCallback,
  } = useContext<UserContextType>(UserContext);
  const locationAuthParamToURL = useLocation();

  const [makePaymentWithSavedCard, {
    data,
    loading,
    error,
  }] = useMutation<
    makePaymentWithSavedCardFromProfile,
    makePaymentWithSavedCardFromProfileVariables
  >(MAKE_PAYMENT_WITH_SAVED_CARD);

  const payWithSavedCard = (
    amount: number,
    cardId: number,
    location: PaymentLocation,
    relatedID: number,
  ) => {
    if (!isUserLoggedIn) {
      setAuthParamToURL(locationAuthParamToURL, FormTypeEnum.Login);
    }

    makePaymentWithSavedCard({
      variables: {
        amount,
        cardID: cardId,
        location,
        relatedID,
      },
    });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setUnfinishedPaymentId(data.makePaymentWithSavedCard.id);
      // todo: maybe combine with previous paymentFinishedCallback's
      // todo: make it work without () =>
      setPaymentFinishedCallback(() =>
        onComplete);
      const isSuccess = data.makePaymentWithSavedCard.errorValue === PaymentError.NoError;

      if (isSuccess && onSuccess) {
        onSuccess(data);
      }
      if (!isSuccess && onFail) {
        onFail(data);
      }
    }
  }, [data, loading, error]);

  return {
    payWithSavedCard,
    loading,
  };
};

export { usePayWithSavedCard, makePaymentWithSavedCardFromProfile as PaymentSuccessDataType };
