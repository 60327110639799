import { gql } from "@apollo/client";

export const USE_PRODUCT = gql`
  mutation useProduct($input: UseProduct!) {
    useProduct(input:$input){
      id
      amount{
        amount
        currency
      }
      state
      resultCode
      errorValue
      datetime
      isFirstPayment
      card{
        id
      }
    }
  }
`;
