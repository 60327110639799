import { gql } from "@apollo/client";

export const GET_PRODUCT_HISTORY = gql`
 query getProductHistory($filter: ProductHistoryFilter!){
  getProductHistory(filter:$filter){
    id
    product {
      name
      id
    }
    status
  }
}
`;
