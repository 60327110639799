import _differenceBy from "lodash/differenceBy";
import _groupBy from "lodash/groupBy";

import { BrowserNameEnum, DeviceTypeEnum } from "@/globalTypes";
import { captureException } from "@/utils/commonUtils";
import { browserDetect, detectDeviceType } from "@/utils/env";

import { MEDIA_CHECK_UI_DATA } from "./constants";
import { MediaDevicesType } from "./types";

/**
 * Stop stream if it's exist
 * @param stream
 */
export function stopStream(stream?: MediaStream) {
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
      stream.removeTrack(track);
    });
  }
}

/**
 * Returns new stream with specified devices
 * @param microphoneId
 * @param videoId
 */
export async function getNewStream(
  videoId: string,
  microphoneId: string,
): Promise<MediaStream | null> {
  const constraints: { audio?: {}; video?: {} } = {};

  constraints.audio = { deviceId: microphoneId ? { exact: microphoneId } : undefined };

  if (videoId) {
    constraints.video = { deviceId: videoId ? { exact: videoId } : undefined };
  }

  return navigator.mediaDevices.getUserMedia(constraints)
    .catch((exception: Error) => {
      captureException(exception, "Re-creating stream with specific devices for video session");
      return new MediaStream();
    })
    .catch((exception: Error) => {
      captureException(exception, "Re-creating empty stream for video session");
      return null;
    });
}

/**
 * Plays audiofile
 * @param id
 * @param element
 */
export function checkSpeakers(id: string, element: HTMLAudioElement | null) {
  const audioElement: HTMLAudioElement | null = element;
  if (!audioElement) {
    return;
  }

  if (
    id
    && detectDeviceType() === DeviceTypeEnum.Desktop
    && browserDetect() !== BrowserNameEnum.Firefox
    && browserDetect() !== BrowserNameEnum.Safari
  ) {
    // @ts-ignore
    audioElement.setSinkId(id);
  }

  audioElement.pause();
  audioElement.currentTime = 0;
  audioElement.play();
}

/**
 * Creates new audio context from media stream and returns it's data
 * @param stream
 * @returns Stream and stream analyser object
 */
export function initMicAudioContext(
  stream: MediaStream | undefined,
): { audioContext: AudioContext, analyser: AnalyserNode } | null {
  if (!stream || !stream.getAudioTracks().length) {
    return null;
  }

  const newAudioContext = new AudioContext();
  const audioSource = newAudioContext.createMediaStreamSource(stream);
  const analyser = newAudioContext.createAnalyser();
  analyser.fftSize = 512;
  analyser.minDecibels = -127;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;
  audioSource.connect(analyser);

  return { audioContext: newAudioContext, analyser };
}

export function getVideoStatusText(
  cameraList: MediaDeviceInfo[],
  isVideoEnabled: boolean,
): string {
  if (!isVideoEnabled) {
    return MEDIA_CHECK_UI_DATA.video.subtext.noAccess;
  }
  if (cameraList.length <= 1) {
    return MEDIA_CHECK_UI_DATA.video.subtext.noDevice;
  }
  return MEDIA_CHECK_UI_DATA.video.subtext.default;
}

export function getMicrophoneStatusText(
  microphoneID: string,
  isMicrophoneEnabled: boolean,
): string | undefined {
  if (!isMicrophoneEnabled) {
    return MEDIA_CHECK_UI_DATA.microphone.subtext.noAccess;
  }
  if (!microphoneID) {
    return MEDIA_CHECK_UI_DATA.microphone.subtext.noDevice;
  }
  return MEDIA_CHECK_UI_DATA.microphone.subtext.default;
}

export function buildDevicesLists(data: MediaDeviceInfo[]): MediaDevicesType {
  const audioinput = data.filter((item) =>
    item.kind === "audioinput" && item.deviceId);
  const videoinput = data.filter((item) =>
    item.kind === "videoinput" && item.deviceId);
  const audiooutput = data.filter((item) =>
    item.kind === "audiooutput");

  if (videoinput.length > 0) {
    videoinput.push({
      deviceId: "",
      label: "Без камеры",
    } as MediaDeviceInfo);
  } else {
    videoinput.push({
      deviceId: "",
      label: "Устройство не найдено",
    } as MediaDeviceInfo);
  }

  if (!audioinput.length) {
    audioinput.push({
      deviceId: "",
      label: "Устройство не найдено",
    } as MediaDeviceInfo);
  }

  // we can't access speakers for these devices so taking the default ones
  if (
    detectDeviceType() !== DeviceTypeEnum.Desktop
    || browserDetect() === BrowserNameEnum.Firefox
    || browserDetect() === BrowserNameEnum.Safari
  ) {
    audiooutput.push({
      deviceId: "",
      label: "По умолчанию",
    } as MediaDeviceInfo);
  } else if (!audiooutput.length) {
    audiooutput.push({
      deviceId: "",
      label: "Устройство не найдено",
    } as MediaDeviceInfo);
  }

  return {
    cameraList: videoinput,
    micList: audioinput,
    speakersList: audiooutput,
  };
}

/**
 * Calculates new auto selected device ID and flag to update dropdown
 * @param newDevices
 * @param oldDevices
 * @returns New auto selected device ID and flag to update dropdown
 */
export const getNewAutoSelectedDeviceId = (
  newDevices: MediaDeviceInfo[],
  oldDevices: MediaDeviceInfo[],
): {
  newAutoSelectedDeviceId: string,
  shouldResetDropdown: boolean
} => {
  const newlyPluggedDevices = _differenceBy(newDevices, oldDevices, (device) =>
    device.deviceId);

  // If there are newly plugged devices - returning first of them
  if (newlyPluggedDevices.length) {
    return { newAutoSelectedDeviceId: newlyPluggedDevices[0].deviceId, shouldResetDropdown: true };
  }

  // If some device was unplugged or nothing was pluggeg or unplugged or was but wery fast
  return { newAutoSelectedDeviceId: newDevices[0].deviceId, shouldResetDropdown: false };
};
