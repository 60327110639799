import { FastPaymentStatusEnum } from "./props";

export const getPaymentStatusClass = (isPaymentInProgress: boolean, paymentStatus: FastPaymentStatusEnum) => {
  switch (true) {
    case isPaymentInProgress:
      return "in-progress";
    case paymentStatus === FastPaymentStatusEnum.DEFAULT:
      return "is-ready";
    case paymentStatus === FastPaymentStatusEnum.SUCCESS:
      return "is-success";
    case paymentStatus === FastPaymentStatusEnum.FAILURE:
      return "is-failure";
    default:
  }
};
